import React, { useState, useEffect } from 'react';
import moment from 'moment';

import styles from './Schedule.module.css'

import Schedules from './Schedules';
import { setExisting } from './utils';

const initialState = {};

const Overview = ({ update, refresh, daysLeft, schedules, clientID }) => {
  const [data, setData] = useState(initialState); // Kommer innehålla data som ska sparas

  const set = (opts) => setExisting({ setData, ...opts }, data);

  // if (!schedules || schedules.length === 0) return null;

  schedules = schedules.filter(({ scheduleStart: start, scheduleEnd: stop }) => (
    moment().isBetween(start, stop, 'days', true) || moment().isBefore(start)
  ));

  schedules = schedules.sort((i1, i2) => {
    const i1Active =  !moment().isBefore(i1.scheduleStart);
    const i2Active =  !moment().isBefore(i2.scheduleStart);

    if ( i1Active !== i2Active) {
      return i2Active - i1Active;
    };

    return i2.id - i1.id;
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, []);

  return (
    <div className={styles.messageBox}>
      <Schedules
        schedules={schedules}
        set={set}
        data={data}
        slug={'overview'}
        disableInput
        update={update}
        refresh={refresh}
        userId={clientID}
      />
    </div>
  );
};

export default Overview;
