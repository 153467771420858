import Modal from '@components/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { isPhoneValid } from '@utils/validate';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TagsInput } from 'react-tag-input-component';
import { sendRegSMS, updateUser } from '../redux/user';
import styles from './addPatient/AddPatient.module.css';

const timeDiffOptions = [
  -13, -12.5, -12, -11.5, -11, -10.5, -10, -9.5, -9, -8.5, -8, -7.5, -7, -6.5,
  -6, -5.5, -5, -4.5, -4, -3.5, -3, -2.5, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2,
  2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11,
  11.5, 12, 12.5, 13,
];

function Patientinformation() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm({mode: 'onChange'});

  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { clientID } = useParams();
  const patient = useSelector(
    ({ user: { clients } }) =>
      clients.filter(({ id }) => `${id}` === clientID)[0]
  );

  const [isOpenReactiveNotice, setOpenReactiveNotice] = useState(false);

  const isReactive = React.useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('reactive');
  }, [search]);

  useEffect(() => {
    if (!patient) {
      return;
    }

    reset({
      firstname: patient.firstname,
      lastname: patient.lastname,
      msisdn: patient.msisdn,
      usercode: patient.usercode,
      clientid: patient.clientID,
      smslevel: patient.smsLevel,
      threshold: patient.ppmLimit,
      smsrecipients: patient.smsRecipients.split(',').filter(v => !!v),
      timeDiff: patient.timeDiff || 0,
      ppmVisible: patient.ppmVisible || false,
    });

    if (!patient.msisdn && isReactive) {
      setOpenReactiveNotice(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, isReactive]);

  const onSubmit = (data) => {
    console.log('onsubmit ', data);

    const userData = {
      ...data,
      userID: parseInt(clientID),
    };
    dispatch(updateUser(userData))
      .then(() => {
        history.push('/controlpanel/activepatient');
      })
      .catch(console.log);
    //setFormBtn(true);
  };

  const sendSMS = () => {
    console.log('sendSMS ' + clientID);
    dispatch(
      sendRegSMS({ userID: parseInt(clientID), message: 'Test sms message' })
    )
      .then(alert('Ett aktiverings-SMS har skickats till klienten'))
      .catch(console.log);
  };

  if (!patient) return null;

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <h2>Klientinställningar</h2>
        <p>
          {patient.firstname} {patient.lastname} (
          {patient.usercode.toUpperCase()})
        </p>
      </div>
      <div className={styles.profile}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form}>
            <div className={styles.firstName}>
              <label>FÖRNAMN *</label>
              <input
                type="text"
                {...register('firstname', {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-zäöåÄÖÅ]+$/i,
                })}
                placeholder="Förnamn"
              />
              {errors.firstname && errors.firstname.type === 'required' && (
                <span className="error-helper">
                  Du måste fylla i ett förnamn
                </span>
              )}
              {errors.firstname && errors.firstname.type === 'maxLength' && (
                <span className="error-helper">Förnamnet är för långt!</span>
              )}
              {errors.firstname && errors.firstname.type === 'pattern' && (
                <span className="error-helper">
                  Får bara innehålla bokstäver
                </span>
              )}
            </div>
            <div className={styles.lastName}>
              <label>EFTERNAMN *</label>
              <input
                type="text"
                {...register('lastname', { required: true, maxLength: 40 })}
                placeholder="Efternamn"
              ></input>
              {errors.lastname && errors.lastname.type === 'required' && (
                <span className="error-helper">
                  Du måste fylla i ett efternamn
                </span>
              )}
              {errors.lastname && errors.lastname.type === 'maxLength' && (
                <span className="error-helper">Efternamnet är för långt!</span>
              )}
            </div>
            <div className={styles.phonenumber}>
              <label>KLIENTENS TELEFONNUMMER *</label>
              <input
                type="text"
                {...register('msisdn', {
                  required: true,
                  maxLength: 10,
                  minLength: 8,
                  pattern: /[0-9]{10}/,
                })}
                placeholder="Telefonnummer"
              ></input>
              {errors.msisdn && errors.msisdn.type === 'required' && (
                <span className="error-helper">
                  Du måste fylla i ett telefonnummer
                </span>
              )}
              {errors.msisdn && errors.msisdn.type === 'maxLength' && (
                <span className="error-helper">
                  Telefonnummret har för många sifror. max 10
                </span>
              )}
              {errors.msisdn && errors.msisdn.type === 'minLength' && (
                <span className="error-helper">
                  Telfonnummret innehåller för få siffror, min 8
                </span>
              )}
              {errors.msisdn && errors.msisdn.type === 'pattern' && (
                <span className="error-helper">Endast siffror</span>
              )}
            </div>
            <div className={styles.patientId}>
              <div className={styles.flex}>
                <label>ALBUS-ID</label>
                <Tooltip title="ID:t genereras slumpmässigt för att skydda din klientens integritet.">
                  <InfoOutlinedIcon style={{ color: 'blue' }} />
                </Tooltip>
              </div>
              <input
                type="text"
                {...register('usercode')}
                placeholder="MANI"
                readOnly
              ></input>
            </div>
            <div className={styles.altpatientId}>
              <label>EGET ID</label>
              <input
                type="text"
                {...register('clientid', { maxLength: 12 })}
                placeholder="Eget ID"
              ></input>
              {errors.clientid && errors.clientid.type === 'maxLength' && (
                <span className="error-helper">ID:et är för långt. max 12</span>
              )}
            </div>
            <div
              className={classNames(styles.contactPhonenumber, {
                error: errors?.smsrecipients,
              })}
            >
              <div className={styles.flex}>
                <label>TELEFONNUMMER FÖR RESULTATDELNING</label>
                <Tooltip
                  title="Du kan välja att automatiskt dela resultat via SMS till exempelvis dig själv, en
                              chef eller anhörig. Skriv in mottagarens mobilnummer i formatet 0701234567
                              och tryck på enter."
                >
                  <InfoOutlinedIcon style={{ color: 'blue' }} />
                </Tooltip>
              </div>
              <Controller
                name="smsrecipients"
                control={control}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  // <ReactTags></ReactTags>
                  <TagsInput
                    key={JSON.stringify(value)}
                    placeHolder="Tryck på enter för att lägga till ett nytt telefonnummer"
                    value={
                      value ||
                      patient.smsRecipients.split(',').filter((v) => v !== '')
                    }
                    beforeAddValidate={(phone) => {
                      const isValid = isPhoneValid(phone);
                      if (!isValid) {
                        setError('smsrecipients', { type: 'isPhoneNumber' });
                      }
                      return isValid;
                    }}
                    {...field}
                    onBlur={(e) => {
                      const phone = e.target.value;
                      clearErrors('smsrecipients');
                      if (!phone) {
                        return;
                      }

                      const isValid = isPhoneValid(phone);
                      if(isValid) {
                        value = [...value, phone].filter(value => !!value);
                        onChange(value)
                      } else {
                        setError('smsrecipients', { type: 'isPhoneNumber' });
                      }
                    }}
                    onChange={(value) => {
                      onChange(value)
                    }}
                  />
                )}
              />
              {errors?.smsrecipients?.type === 'isPhoneNumber' && (
                <span className="error-helper">
                  Telefonnumret är inte i rätt format
                </span>
              )}
            </div>
            <div className={styles.signal}>
              <div className={styles.flex}>
                <label>DELA RESULTAT VIA SMS VID:</label>
                <Tooltip title="Här väljer du vilka resultat som ska skickas till telefonnumret för resultatdelning.">
                  <InfoOutlinedIcon style={{ color: 'blue' }} />
                </Tooltip>
              </div>
              <select type="text" {...register('smslevel')}>
                <option value="">Missade och över promillegräns</option>
                <option value="all">Alla resultat</option>
              </select>
            </div>

            <div className={styles.threshold}>
              <label style={{ marginRight: '10px' }}>PROMILLEGRÄNS</label>
              <Tooltip title="Välj var gränsen går mellan ett godkänt och icke godkänt resultat.">
                <InfoOutlinedIcon style={{ color: 'blue' }} />
              </Tooltip>
              <select type="number" {...register('threshold')}>
                <option value="0.00">0.00</option>
                <option value="0.10">0.10</option>
                <option value="0.20">0.20</option>
              </select>
            </div>
            <div>
              <label style={{ marginRight: '10px' }}>TIDSSKILLNAD</label>
              <Tooltip title="Befinner sig klienten i en annan tidszon? Ange då här hur många timmars tidsskillnad det är mot svensk tid (CET).">
                <InfoOutlinedIcon style={{ color: 'blue' }} />
              </Tooltip>
              <select type="number" {...register('timeDiff')}>
                {timeDiffOptions.map((time, index) => (
                  <option key={index} value={time}>{`${
                    time < 0 ? time : `+${time}`
                  } h`}</option>
                ))}
              </select>
            </div>
            <div className={styles.ppmVisible}>
              <div className={styles.flex}>
                <label>VISA MÄTRESULTAT FÖR KLIENTEN?</label>
              </div>
              <select {...register('ppmVisible')}>
                <option value={false} >Nej, visa inget promille efter blås</option>
                <option value={true} >Ja, visa promille efter blås</option>
              </select>
            </div>
          </div>
          <div>
            <button
              className={`${styles.btnSaveC} ${styles.btnSaveCRight}`}
              type="button"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            >
              Spara ändringar
            </button>
            <button className={styles.btnSaveC} type="button" onClick={sendSMS}>
              Skicka nytt aktiverings-SMS
            </button>
          </div>
        </form>
      </div>
      <Modal
        modalOpen={isOpenReactiveNotice}
        content="Kom ihåg att du behöver skriva in klientens telefonnummer på nytt"
        onConfirm={() => {
          setOpenReactiveNotice(false);
          setFocus('msisdn');
        }}
        confirmLabel={'Ok'}
        iconColor={'orange'}
      />
    </div>
  );
}

export default Patientinformation;
