import moment from 'moment';
import { useEffect } from 'react';
import Icon from './Icon';
import styles from './Schedule.module.css';

const Input = ({ onChange, className, value, disabled, placeholder }) => (
  <input
    type="text"
    onChange={onChange}
    className={className}
    value={value}
    disabled={disabled}
    maxLength="30"
    placeholder={placeholder}
  />
);

const ScheduleHead = ({
  start,
  stop,
  slug,
  sname,
  disabled,
  setSName,
  active,
  upcoming,
  isExpand,
  changeExpand
}) => {
  const today = moment().format('YYYY-MM-DD');
  const create = slug === 'create';
  const diff = -(active
    ? moment().diff(stop, 'days')
    : moment().diff(start, 'days'));

  let text = '';
  const tillsvidare = moment().diff(stop, 'days')  <= -(5 * 365);

  if (start === today) {
    start = 'Idag';
  }

  if (stop === today) {
    stop = 'Idag';
  } else if (tillsvidare) {
    stop = 'Tillsvidare';
  }


  if (upcoming) text = `(Aktiveras om ${diff} dagar)`;
  if (!upcoming) text = `(Löper ut om ${diff} dagar)`;
  if (diff === 0) text = 'Inaktiveras imorgon';
  if (diff < 0) text = 'Inaktiverat';

  if (!upcoming && diff > 30) text = '';

  const handleInput = ({ target: { value } }) => setSName(value);
  
  return (
    <div className={styles.schemCircle}>
      {create ? (
        <Input
          onChange={handleInput}
          className={styles.inputBorder}
          value={sname}
          disabled={disabled}
          placeholder="Ange schemanamn"
        />
      ) : (
        <>
          <Icon active={active} upcoming={upcoming} />
          <Input
            onChange={handleInput}
            className={styles.inputShow + ' flex-initial w-20'}
            value={sname}
            disabled={disabled}
            maxLength="30"
          />
          {
            disabled && 
            <>
              <div className={styles.headerTime + ' flex-initial flex'}>
                <div className={styles.headerTimeItem}>
                  <span className={styles.headerTimeLabel}>Start: </span>
                  <span>{start}</span>
                </div>
                <div>
                  <span className={styles.headerTimeLabel}>Aktivt till: </span>
                  <span>{stop}</span>
                </div>
              </div>
              <div className={styles.headerStatus + ' flex-initial'}>(
                {active && 'Aktive'}
                {upcoming && 'Kommande'}
                {!active && !upcoming && 'Inaktiva'}
              )
              </div>
              
              {
                !isExpand &&  
                <span className="cursor-pointer material-symbols-rounded" onClick={() => {changeExpand && changeExpand(true)}}>
                  expand_more
                </span>
              }

              {
                isExpand &&  
                <span className="cursor-pointer material-symbols-rounded"  onClick={() => {changeExpand && changeExpand(false)}}>
                  expand_less
                </span>
              }
            </>
           
          }
          
          {/* <div className={styles.daysleft + ' flex-initial'}>{text}</div> */}
        </>
      )}
    </div>
  );
};

export default ScheduleHead;
