/* eslint-disable no-restricted-globals */
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@mui/material/Icon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteSchedule } from '../../redux/user';
import Button from '../Button';
import Modal from '../Modal';
import DatePicker from './DatePicker';
import styles from './Schedule.module.css';
import ScheduleHead from './ScheduleHead';
import ScheduleItem from './ScheduleItem';

const Schedule = ({
  scheduleStart,
  scheduleEnd,
  scheduleId,
  scheduleItems,
  set,
  data,
  index,
  slug,
  scheduleName,
  disableInput,
  refresh = false,
  update = false,
  userId,
  setErr,
  err,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [start, setStart] = useState(scheduleStart);
  const [isExpand, changeExpand] = useState(true);
  const [stop, setStop] = useState(scheduleEnd);
  const [sname, setSname] = useState(scheduleName);
  const [init, setInit] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);

  const active =
    moment().isBetween(start, stop, 'days', true) && slug !== 'create';
  const upcoming = !active && moment().isBefore(start);
  const inactive = !upcoming && !active;
  const diff = moment().diff(stop, 'days');
  const today = moment().format('YYYY-MM-DD');
  
  let stopLabel = '';
  if (stop === today) {
    stopLabel = 'Idag';
  } else if (diff <= -(5 * 365)) {
    stopLabel = 'Tillsvidare';
  }

  const changeStart = (data) => {
    const value = moment(data);
    const validate = value.isBefore(stop);

    if (slug === 'create' && !value.isAfter(moment().subtract(1, 'days'))) {
      alert('Startdatum kan vara tidigast idag.');
    } else if (validate) {
      setStart(value.format('YYYY-MM-DD'));
    } else {
      alert('Startdatum måste vara före slutdatum.');
    }
  };

  const changeStop = (data) => {
    const stop = moment(data);
    const validate = stop.isSameOrAfter(start);
    if (validate) {
      setStop(stop.format('YYYY-MM-DD'));
    } else {
      alert('Slutdatum måste ligga efter startdatum');
    }
  };

  const add = () => {
    set({
      mode: 'create',
      scheduleId,
      item: {
        scheduleItemTimeStart: '12:00',
        scheduleItemTimeEnd: '13:00',
        scheduleItemDayStart: '1',
        scheduleItemDayEnd: '5',
        scheduleId,
      },
    });
  };

  const onConfirm = () => {
    setIsOpen(false);
    cancel();
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  const cancel = () => {
    // eslint-disable-next-line
    if (!scheduleId) return;

    const scheduleEnd = moment().subtract(1, 'days').format('YYYY-MM-DD');

    const newData = {};
    newData[scheduleId] = {
      stop: scheduleEnd,
      deactive: true,
      create: [],
      update: {},
      remove: {},
    };

    update(newData);
  };

  const remove = () => {
    if (
      !scheduleId ||
      !confirm('Är du säker att du vill ta bort detta schema?')
    )
      return;

    dispatch(deleteSchedule({ scheduleId, userId })).then(() => {
      if (refresh) refresh();
    });
  };

  useEffect(() => {
    if (init) {
      setInit(false);
    } else {
      set({
        mode: 'update',
        scheduleId,
        data: {
          start,
          stop,
          sname,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, stop, sname, scheduleStart, scheduleEnd, scheduleName]);

  useEffect(() => {}, [sname, scheduleName]);

  useEffect(() => {
    setStart(scheduleStart);
    setStop(scheduleEnd);
    setSname(scheduleName);
  }, [scheduleStart, scheduleEnd, scheduleName]);

  // Modal Content
  const content = <p className={styles.name}></p>;
  const labelModal = 'Är du säker på att du vill inaktivera detta schema?';

  const renderButtons = () => {
    if (!disableInput) {
      return (
        <Button color="secondary" onClick={add}>
          + Nytt tillfälle
        </Button>
      );
    }

    return (
      <>
        <div>
          {!inactive && (
            <Button color="blue-dark" to={`/edit/${scheduleId}`}>
              Redigera
            </Button>
          )}
          {active && (
            <Button color="secondary" onClick={() => setIsOpen(true)}>
              Inaktivera
            </Button>
          )}
          {inactive && (
            <Button color="blue-dark" to={`/templates/${scheduleId}`}>
              Återanvänd
            </Button>
          )}
          {!active && (
            <Button color="secondary" onClick={remove}>
              Radera
            </Button>
          )}
        </div>

        {!inactive && (
          <Tooltip title="Duplicera schema">
            <Icon
              baseClassName="material-symbols-outlined"
              className="cursor-pointer text-blue-dark hover:opacity-50"
              style={{ fontSize: '30px' }}
              onClick={() => {
                history.push(`/templates/${scheduleId}`);
              }}
            >
              control_point_duplicate
            </Icon>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        modalOpen={modalIsOpen}
        label={labelModal}
        content={content}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmLabel={'Inaktivera'}
        cancelLabel={'Avbryt'}
        iconColor={'orange'}
      />
      <li key={scheduleId} className={styles.itemLi}>
        <ScheduleHead
          start={start}
          stop={stop}
          setSName={setSname}
          slug={slug}
          sname={sname}
          disabled={disableInput}
          active={active}
          upcoming={upcoming}
          isExpand={isExpand}
          changeExpand={changeExpand}
        />
        {isExpand && (
          <>
            {!disableInput && (
              <div className={styles.flexSch}>
                <DatePicker
                  key="start"
                  name="start"
                  value={start}
                  className={styles.scheduleStart}
                  onChange={changeStart}
                  disabled={disableInput}
                  label="Startdatum: "
                  altLabel="Idag"
                  alt={today === start}
                />
                <DatePicker
                  key="stop"
                  name="stop"
                  value={stop}
                  className={styles.scheduleStart}
                  onChange={changeStop}
                  disabled={disableInput}
                  label="Gäller till: "
                  altLabel={stopLabel}
                  alt={stopLabel}
                />
              </div>
            )}
            <ol className={styles.olTillf}>
              {scheduleItems &&
                scheduleItems.length > 0 &&
                scheduleItems.map((item, index) => {
                  const modified =
                    data[scheduleId] &&
                    data[scheduleId].update &&
                    data[scheduleId].update[item.scheduleItemId];
                  const deleteItem =
                    data[scheduleId] &&
                    data[scheduleId].remove &&
                    data[scheduleId].remove[item.scheduleItemId];

                  if (deleteItem) return null;

                  const adjust = scheduleItems.filter((item, i) => {
                    const deleteItem =
                      data[scheduleId] &&
                      data[scheduleId].remove &&
                      data[scheduleId].remove[item.scheduleItemId];
                    return deleteItem && i < index;
                  }).length;
                  const label = index - adjust;

                  return (
                    <ScheduleItem
                      item={item}
                      key={item.scheduleItemId}
                      set={set}
                      modified={modified}
                      index={index}
                      labelNr={label}
                      deleteItem={deleteItem}
                      disableInput={disableInput}
                      setErr={setErr}
                      err={err}
                    />
                  );
                })}
              {data[scheduleId] &&
                data[scheduleId].create &&
                data[scheduleId].create.length > 0 &&
                data[scheduleId].create.map((item, index) => {
                  const modified = true;
                  const deleteItem = false;
                  if (item === null) {
                    return null;
                  }

                  const itemLength = scheduleItems.length;
                  const deletedLength = scheduleItems.filter((item) => {
                    const deleteItem =
                      data[scheduleId] &&
                      data[scheduleId].remove &&
                      data[scheduleId].remove[item.scheduleItemId];
                    return deleteItem;
                  }).length;

                  const deleted =
                    data[scheduleId] &&
                    data[scheduleId].create &&
                    data[scheduleId].create.length > 0
                      ? data[scheduleId].create.filter(
                          (item, i) => item === null && i < index
                        ).length
                      : 0;

                  const label = index + itemLength - deletedLength - deleted;

                  return (
                    <ScheduleItem
                      item={item}
                      key={index}
                      set={set}
                      modified={modified}
                      index={index + itemLength}
                      newIndex={index}
                      labelNr={label}
                      deleteItem={deleteItem}
                      disableInput={disableInput}
                      setErr={setErr}
                      err={err}
                    />
                  );
                })}
            </ol>
            <div className="flex justify-between items-center mx-35px mb-15px ">
              {renderButtons()}
            </div>
          </>
        )}
        <div className='scheduleItemBorder'></div>
      </li>
    </>
  );
};

export default Schedule;
