import moment from 'moment';

const FORMAT = 'YYYY-MM-DD';
const startDate = () => moment();
const endDate = () => moment().add(10, 'y').add(1, "d");

// "Empty" schedule
export const newScheduleData = () => (
  [{
    scheduleId: 0,
    scheduleStart: startDate().format(FORMAT),
    scheduleEnd: endDate().format(FORMAT),
    scheduleItems: [],
  }]
);

export const newTestWindowSchedule = () => (
  [{
    scheduleId: 0,
    scheduleStart: startDate().format(FORMAT),
    scheduleEnd: startDate().format(FORMAT),
    scheduleItems: [],
  }]
);

// Used when creating a new schedule
// Needs to diff from rest of the data to trigger a save
export const scheduleData = () => (
  {
    0: {
      start: startDate().subtract(1, "d").format(FORMAT),
      stop: endDate().subtract(1, "d").format(FORMAT),
      sname: '',
      create: [{
        scheduleItemDayStart: 1,
        scheduleItemDayEnd: 5,
        scheduleItemTimeStart: "00:00:00",
        scheduleItemTimeEnd: "23:00:00",
      }]
    },
  }
);

export const scheduleData_3_items = (create) => (
  {
    0: {
      start: startDate().format(FORMAT),
      stop: endDate().subtract(1, "d").format(FORMAT),
      create: (create || [{
        scheduleItemDayStart: 1,
        scheduleItemDayEnd: 5,
        scheduleItemTimeStart: "08:00:00",
        scheduleItemTimeEnd: "09:00:00",
      }, {
        scheduleItemDayStart: 1,
        scheduleItemDayEnd: 5,
        scheduleItemTimeStart: "12:00:00",
        scheduleItemTimeEnd: "13:00:00",
      }, {
        scheduleItemDayStart: 1,
        scheduleItemDayEnd: 5,
        scheduleItemTimeStart: "17:00:00",
        scheduleItemTimeEnd: "18:00:00",
      }])
    },
  }
);

export const schedulTestWindow = (create) => (
  {
    0: {
      start: startDate().format(FORMAT),
      stop: startDate().format(FORMAT),
      create: (create || [{
        scheduleItemDayStart: 1,
        scheduleItemDayEnd: 7,
        scheduleItemTimeStart: startDate().format("HH:mm:00"),
        scheduleItemTimeEnd: startDate().add(1, "h").format("HH:mm:00"),
      }])
    },
  }
);
