import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import {
  useParams,
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink as Link,
} from "react-router-dom";

import { updateSchedule, updateScheduleItem, deleteScheduleItem, newScheduleItem } from "../../redux/user";
import Overview from './Overview';
import Edit from './Edit';
import CreateTemplate from './CreateTemplate';
import Inactive from './Inactive';
import Button from '../Button';
import { runJobs } from './utils';

import styles from './Schedule.module.css'
import { getSchedule, schedulesSelector } from '../../redux/patient'

const Navigation = ({ set }) => {
  const buttons = [
    { slug: "create", label: '+ Skapa nytt schema' },
    { slug: "create/test-window", label: '+ Enstaka tillfälle' },
  ];

  return (
    <div className={styles.topBtn}>
      {
        buttons
        && buttons.length > 0
        && buttons.map(({ slug, label }) => (
          <Button color="blue-dark" to={`/${slug}`} key={slug}>
            {label}
          </Button>
        ))
      }
    </div>
  )
}

const DefaultTabs = () => (
  <div className={styles.meny}>
    <Link activeClassName={styles.mActive} className={styles.m1} exact to="/">Aktiva & kommande</Link>
    <Link activeClassName={styles.mActive} className={styles.m1} to="/inactive">Inaktiva</Link>
  </div>
)

const Patient = ({ firstname, lastname, usercode }) => (
  <p>{firstname} {lastname} ({usercode.toUpperCase()})</p>
);

const Schedule = (props) => {

  const dispatch = useDispatch();
  const { clientID } = useParams();
  const patient = useSelector(({ user: { clients } }) => clients.filter(({ id }) => `${id}` === clientID)[0]);
  const schedules = useSelector(schedulesSelector);
  const refresh = async () => {
    if (!patient) return null;

    const { id } = patient;
    return dispatch(getSchedule(id));
  }

  const update = (data) => (
    runJobs({
      data,
      updateSchedule,
      deleteScheduleItem,
      updateScheduleItem,
      newScheduleItem,
      dispatch,
      userId: clientID,
    }).then(() => {
      return refresh();
    })
  );

  if (!patient) return null;

  return (
    <Router basename={`/schedule/${clientID}`}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <h2>Blåsschema</h2>
            <Tooltip title={`Det är under schemats tillfällen som klienten ska genomföra sina blås. Klienten
                         får en påminnelse via SMS 30 och 15 minuter innan ett blåstillfälle tar slut.`}>
              <InfoOutlinedIcon style={{ color: "blue" }} />
            </Tooltip>
          </div>
          <div className={styles.topBtn}>
            <Navigation />
          </div>
          <div className={styles.flex1} />
          <Patient {...patient} />
        </div>

        <Switch>
          <Route exact path="/">
            <DefaultTabs />
            <Overview
              refresh={refresh}
              update={update}
              schedules={schedules}
              clientID={clientID}
            />
          </Route>
          <Route exact path="/inactive">
            <DefaultTabs />
            <Inactive
              schedules={schedules}
              refresh={refresh}
              clientID={clientID}
            />
          </Route>

          <Route exact path="/edit/:id">
            <DefaultTabs />
            <Edit
              update={update}
              schedules={schedules}
              clientID={clientID}
            />
          </Route>
          <Route exact path="/create/test-window">
            <CreateTemplate
              key="test-window"
              refresh={refresh}
              clientID={clientID}
              testWindow
            />
          </Route>
          <Route exact path="/create">
            <CreateTemplate
              key="create"
              refresh={refresh}
              clientID={clientID}
            />
          </Route>
          <Route path="/templates/:template_id">
            <CreateTemplate
              key="template"
              refresh={refresh}
              schedules={schedules}
              clientID={clientID}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default Schedule;
