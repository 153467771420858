import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const Icon = ({ active, upcoming }) => {
  let color = 'grey';

  if (active) color = "SpringGreen";
  if (upcoming) color = "Navy";

  return (
    <CircleIcon style={{ color }} className='flex-none'/>
  );
}

export default Icon;
